<template>
  <el-dialog
    title="方案详情"
    append-to-body
    :visible.sync="visible"
    width="70%"
    v-loading="loading"
    @close="closeCallback">
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px" shadow="never">
    <div class="i-title">
      <h3><i class="mr-1"></i>产品信息</h3>
    </div>
    <div v-for="(item, index) in dataForm.productList"
         :key="index" class="list">
      <el-card shadow="never" class="card">
      <el-row class="i-row">
        <el-col :span="8" :offset="1">
          <label>服务产品：</label><span>{{ item.skuName }}</span>
        </el-col>
      </el-row>
      <el-row class="i-row">
        <el-col :span="8" :offset="1">
          <label>产品审核状态：</label><span><el-tag :type="tagType[item.audit]">{{ auditDict[item.audit] }}</el-tag></span>
        </el-col>
        <el-col :span="8">
          <label v-if="item.auditDesc !== null">驳回原因：</label><span>{{ item.auditDesc }}</span>
        </el-col>
      </el-row>
      <el-row class="i-row">
        <el-col :span="7" :offset="1">
          <label>业务办理地区：</label><span>{{ item.area }}</span>
        </el-col>
        <el-col :span="7" :offset="1">
          <label>售价：</label><span>{{ item.productHighestPrice }}</span>
        </el-col>
        <el-col :span="7" :offset="1">
          <label>最低价：</label><span>{{ item.productLowestPrice }}</span>
        </el-col>
      </el-row>
      <el-row class="i-row">
        <el-col :span="7" :offset="1">
          <label>产品报价：</label><span>{{ item.price }}</span>
        </el-col>
        <el-col :span="7" :offset="1">
          <label>产品数量：</label><span>{{ item.num }}</span>
        </el-col>
        <el-col :span="7" :offset="1">
        </el-col>
      </el-row>
<!--      <el-row class="i-row">-->
<!--        <el-col :span="5" :offset="1">-->
<!--          <label>首期：</label><span>{{ item.phaseOne }}</span>-->
<!--        </el-col>-->
<!--        <el-col :span="5" :offset="1">-->
<!--          <label>二期：</label><span>{{ item.phaseTwo }}</span>-->
<!--        </el-col>-->
<!--        <el-col :span="5" :offset="1">-->
<!--          <label>三期：</label><span>{{ item.phaseThree }}</span>-->
<!--        </el-col>-->
<!--        <el-col :span="5" :offset="1">-->
<!--          <label>四期：</label><span>{{ item.phaseFour }}</span>-->
<!--        </el-col>-->
<!--      </el-row>-->
      </el-card>
    </div>
      <el-divider></el-divider>
      <div class="i-title">
        <h3><i class="mr-1"></i>产品总计</h3>
      </div>
      <el-card shadow="never">
        <el-row class="i-row">
          <el-col :span="7" :offset="1">
            <label>总金额：</label><span>{{ dataForm.totalSum }}</span>
          </el-col>
        </el-row>
        <el-row class="i-row">
          <el-col :span="7" :offset="1">
            <label>方案状态：</label><span>{{ dataForm.statusName }}</span>
          </el-col>
        </el-row>
        <el-row class="i-row">
          <el-col  :span="7" :offset="1">
            <label>办理公司：</label><span>{{ dataForm.company }}</span>
          </el-col>
        </el-row>
        <el-row class="i-row">
          <el-col  :span="23" :offset="1">
            <label>备注：</label><span>{{ dataForm.note }}</span>
          </el-col>
        </el-row>
        <el-row class="i-row">
          <el-col :span="7" :offset="1">
            <label>创建时间：</label><span>{{ dataForm.createTime }}</span>
          </el-col>
          <el-col :span="7" :offset="1">
            <label>创建人：</label><span>{{ dataForm.creatorName }}</span>
          </el-col>
        </el-row>
      </el-card>
<!--    <el-divider></el-divider>-->
<!--    <div class="i-title">-->
<!--      <h3><i class="mr-1"></i>付款方式</h3>-->
<!--    </div>-->
<!--    <el-card shadow="never">-->
<!--      <el-row class="i-row">-->
<!--        <el-col :span="5" :offset="1">-->
<!--          <label>首期：</label><span>{{ dataForm.phaseOne }}</span>-->
<!--        </el-col>-->
<!--        <el-col :span="5" :offset="1">-->
<!--          <label>二期：</label><span>{{ dataForm.phaseTwo }}</span>-->
<!--        </el-col>-->
<!--        <el-col :span="5" :offset="1">-->
<!--          <label>三期：</label><span>{{ dataForm.phaseThree }}</span>-->
<!--        </el-col>-->
<!--        <el-col :span="5" :offset="1">-->
<!--          <label>四期：</label><span>{{ dataForm.phaseFour }}</span>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--    </el-card>-->
    </el-card>
  </el-dialog>
</template>

<script>
  export default {
    name: 'item-product',
    data () {
      return {
        visible: false,
        loading: false,
        dataForm: {
          productList: []
        },
        dataList: [],
        auditDict: {},
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        tagType: {0: '', 1: 'success', 2: 'danger'}
      }
    },
    created () {
      this.$getDictMap('AUDIT_STATUS', (dict) => {
        this.auditDict = dict.AUDIT_STATUS
      })
    },
    methods: {
      init (id) {
        this.visible = true
        this.getSolutionInfo(this.$deepClone(id))
      },
      getSolutionInfo (id) {
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl(this.$api.SOLUTION.INFO + '/' + id),
          method: 'get'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm = data.info
          } else {
          }
          this.dataListLoading = false
        })
      },
      closeCallback () {
        this.visible = false
        this.$emit('closeCallback')
      }
    }
  }
</script>

<style scoped>
  .i-row {
    display: flex;
    /*height: 36px;*/
    line-height: 36px;
    color: #303133;
  }

  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }

  .i-row label {
    font-weight: bold;
    color: #1a2633;
  }

  /* 奇数行的样式 */
  .list:nth-child(odd)>.card {
    background: #f8fbfd;
  }

</style>
