<template>
  <div class="mod-config">
    <template v-if="page === 'list'">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList">
      <el-form-item>
        <el-input v-model="dataForm.needNo" placeholder="请输入需求编号" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="dataForm.status" placeholder="请选择需求状态" clearable>
          <el-option
            v-for="(val, key) in statusDict"
            :key="key"
            :label="val"
            :value="key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <service-item-cascader v-model="dataForm.itemCode"></service-item-cascader>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="dataForm.createTimeRange"
          value-format="yyyy-MM-dd"
          type="daterange"
          unlink-panels
          :clearable="false"
          :pickerOptions="pickerOptions"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 260px">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrFollow">添加需求</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%;">
      <el-table-column
        prop="needNo"
        header-align="center"
        align="center"
        width="200"
        label="需求编号">
      </el-table-column>
      <el-table-column
        prop="publishName"
        header-align="center"
        align="center"
        label="联系人">
      </el-table-column>
      <el-table-column
        prop="publishMobile"
        header-align="center"
        align="center"
        label="联系人手机">
      </el-table-column>
      <el-table-column
        prop="orgName"
        header-align="center"
        align="center"
        label="所属地区">
      </el-table-column>
      <el-table-column
        prop="itemName"
        header-align="center"
        align="center"
        label="所属服务项">
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="需求状态">
        <template slot-scope="scope">
          <el-tag :type="tagType[scope.row.status]">{{ statusDict[scope.row.status] }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        width="160"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="followTime"
        header-align="center"
        align="center"
        width="160"
        label="最后跟进时间">
      </el-table-column>
      <el-table-column
        prop="creatorName"
        header-align="center"
        align="center"
        label="创建人">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="changePage('detail', scope.row)">详情</el-button>
          <el-button v-if="scope.row.status==='ygb'" type="text" size="small" @click="deleteNeed(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="tac mt-3"
      background
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    </template>
    <!-- 弹窗, 新增 / 修改 -->
    <need-detail ref="detailNeed" v-if="page === 'detail'" @goBack="goBack"/>
    <need-follow-or-add v-if="addOrUpdateVisible" ref="addNeed" @closeCallback="closeCallback"/>
   </div>
</template>

<script>
  import moment from 'moment'
  import NeedDetail from './need-detail'
  import NeedFollowOrAdd from './need-follow-or-add'
  import ServiceItemCascader from '../../../components/service-item-cascader'
  export default {
    name: 'need',
    components: {
      NeedDetail,
      NeedFollowOrAdd,
      ServiceItemCascader
    },
    computed: {
      mobile: {
        get () { return this.$store.state.user.mobile },
        set (val) { this.$store.commit('user/updateMobile', val) }
      }
    },
    data () {
      return {
        dataForm: {
          publishName: '',
          status: '',
          itemCode: '',
          itemName: '',
          createTimeRange: []
        },
        page: 'list',
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        pickerOptions: {
          shortcuts: [{
            text: '本月',
            onClick (picker) {
              let begin = new Date()
              begin.setTime(new Date(begin.getFullYear(), begin.getMonth(), 1))
              let end = new Date()
              picker.$emit('pick', [begin, end])
            }
          }, {
            text: '今年至今',
            onClick (picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一年',
            onClick (picker) {
              const end = new Date()
              const start = new Date(end.getTime() - 3600 * 1000 * 24 * 365)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        statusDict: {},
        tagType: {dgj: '', dyhqr: '', ywc: 'success', ycd: '', ygb: 'danger'}
      }
    },
    created () {
      this.initDict()
      // 初始化查询时间范围
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
      this.dataForm.createTimeRange = [start, end]
      this.getDataList(true)
    },
    methods: {
      initDict () {
        this.$getDictMap('NEED_STATUS', (dict) => {
          this.statusDict = dict.NEED_STATUS
        })
      },
      // 获取数据列表
      getDataList (reset) {
        this.dataListLoading = true
        if (reset) {
          this.pageIndex = 1
        }
        let [beginDate, endDate] = this.dataForm.createTimeRange || []
        this.$http({
          url: this.$http.adornUrl(this.$api.NEED.LIST),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'status': this.dataForm.status || null,
            'itemCode': this.dataForm.itemCode || null,
            'publishName': this.dataForm.publishName || null,
            'publishMobile': this.dataForm.publishMobile || null,
            'needNo': this.dataForm.needNo || null,
            'beginDate': beginDate ? moment(beginDate).format('YYYY-MM-DD') : null,
            'endDate': endDate ? moment(endDate).format('YYYY-MM-DD') : null
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList(true)
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList(false)
      },
      closeCallback (refresh) {
        this.addOrUpdateVisible = false
        if (refresh) {
          this.getDataList(true)
        }
      },
      addOrFollow () {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs['addNeed'].init()
        })
      },
      // 切换页面
      changePage (page, rowData) {
        this.page = page
        this.$nextTick(() => {
          this.$refs[page + 'Need'].init(rowData.id)
        })
      },
      // 页面回调
      goBack (refresh) {
        this.page = 'list'
        if (refresh) {
          this.getDataList(true)
        }
      },
      deleteNeed (row) {
        this.$confirm('确定删除需求[' + row.needNo + ']?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(this.$api.NEED.DELETE + '/' + row.id),
            method: 'post',
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message.success('删除成功')
              this.getDataList(true)
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>
