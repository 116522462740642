<template>
  <div>
    <el-page-header @back="$emit('goBack')">
      <span slot="content" class="fwb">需求详情</span>
    </el-page-header>
    <el-button v-if="(dataForm.status!=='ygb' || dataForm.status!=='ywc')"
               style="position: absolute;top: 20px;right: 60px" size="small" type="danger" @click="closeNeed">关闭需求
    </el-button>
    <el-divider></el-divider>
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
      <div class="i-title">
        <h3><i class="el-icon-s-order mr-1"></i>需求信息</h3>
      </div>
      <el-row class="i-row">
        <el-col :span="1">
        </el-col>
        <el-col :span="8">
          <label>需求编号：</label><span>{{ dataForm.needNo }}</span>
        </el-col>
        <el-col :span="8">
          <label>需求状态：</label><span><el-tag :type="needType[dataForm.status]">{{ statusDict[dataForm.status] }}</el-tag></span>
        </el-col>
        <el-col :span="8">
          <label>意向产品：</label><span>{{ dataForm.serviceName }}</span>
        </el-col>
      </el-row>
      <el-row class="i-row">
        <el-col :span="1">
        </el-col>
        <el-col :span="8">
          <label>需求质量：</label><span>{{ qualityDict[dataForm.quality] }}</span>
        </el-col>
        <el-col :span="8">
          <label>意向金额：</label><span>无</span>
        </el-col>
        <el-col :span="8">
          <label>创建时间：</label><span>{{ dataForm.createTime }}</span>
        </el-col>
      </el-row>
      <el-row class="i-row">
        <el-col :span="1">
        </el-col>
        <el-col :span="8">
          <label>联系人姓名：</label><span>{{ dataForm.publishName }}</span>
        </el-col>
        <el-col :span="8">
          <label>联系电话：</label><span>{{ dataForm.publishMobile }}</span>
        </el-col>
        <el-col :span="8">
          <label>邮箱：</label><span>{{ dataForm.mailbox }}</span>
        </el-col>
      </el-row>
      <el-row class="i-row">
        <el-col :span="1">
        </el-col>
        <el-col :span="8">
          <label>公司名称：</label>
          <span v-if="dataForm.company">{{ dataForm.company }}</span>
          <span v-else>无</span>
        </el-col>
        <el-col :span="8">
          <label>公司区域：</label><span>{{ dataForm.orgName }}</span>
        </el-col>
        <el-col :span="8">
        </el-col>
      </el-row>
      <el-row class="i-row">
        <el-col :span="1">
        </el-col>
        <el-col :span="24">
          <label>需求备注：</label>
          <span v-if="dataForm.publishContent">{{ dataForm.publishContent }}</span>
          <span v-else>无</span>
        </el-col>
      </el-row>
      <el-row class="i-row">
        <el-col :span="1">
        </el-col>
        <el-col :span="24" v-if="dataForm.status === 'ygb'">
          <label>关闭原因：</label><span>{{ dataForm.closeDesc }}</span>
        </el-col>
      </el-row>
    </el-card>
<!--    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">-->
<!--      <div class="i-title">-->
<!--        <h3><i class="el-icon-s-order mr-1"></i>跟进记录</h3>-->
<!--      </div>-->
<!--      <div v-if="dataForm.followList.length>0">-->
<!--      <el-table-->
<!--        :data="dataForm.followList"-->
<!--        border-->
<!--        v-loading="dataListLoading"-->
<!--        size="small"-->
<!--        style="width: 100%;">-->
<!--        <el-table-column-->
<!--          type="index"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          width="50"-->
<!--          label="#">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          prop="followContent"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          label="跟进详情">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          prop="followStaffNo"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          label="跟进人">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--          prop="followTime"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          label="跟进时间">-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--      </div>-->
<!--        <img v-else src="~@/assets/img/zwsj.jpg" alt="" class="zwsj">-->
<!--    </el-card>-->
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
      <div class="i-title">
        <h3><i class="el-icon-s-order mr-1"></i>方案信息</h3>
      </div>
      <el-table
        :data="currentList"
        border
        v-loading="dataListLoading"
        size="small"
        style="width: 100%;">
        <el-table-column
          type="index"
          header-align="center"
          align="center"
          width="50"
          label="#">
        </el-table-column>
        <el-table-column
          prop="solutionNo"
          header-align="center"
          align="center"
          label="方案编号">
        </el-table-column>
        <el-table-column
          prop="statusName"
          header-align="center"
          align="center"
          label="方案状态">
          <template slot-scope="scope">
            <el-tag :type="tagType[scope.row.status]">{{scope.row.statusName}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="company"
          header-align="center"
          align="center"
          label="办理公司">
        </el-table-column>
        <el-table-column
          prop="createTime"
          header-align="center"
          align="center"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="creatorName"
          header-align="center"
          align="center"
          label="创建人">
        </el-table-column>
        <el-table-column
          width="150"
          header-align="center"
          align="center"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="detailSolutionInfo(scope.row.id)">详情</el-button>
            <el-button v-if="(scope.row.status==='yzf' || scope.row.status==='ybh')" type="text" size="small"
                       @click="deleteSolution(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px;text-align: right"
        small
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, prev, pager, next, jumper">
      </el-pagination>
    </el-card>
    <need-detail-soultion v-if="detailVisible" ref="detailSolution"
                          @closeCallback="closeCallback"></need-detail-soultion>
  </div>
</template>

<script>
  import NeedDetailSoultion from './need-detail-solution'
  import {checkIsNull} from '@/utils'

  export default {
    name: 'need-detail',
    components: {
      NeedDetailSoultion
    },
    data() {
      return {
        solutionList: [{}],
        visible: false,
        dataForm: {
          followList: [],
          solutionList: []
        },
        needId: '',
        dataListLoading: false,
        solutionForm: {},
        addOrUpdateVisible: false,
        detailVisible: false,
        staffVisible: false,
        sourceDict: {},
        statusDict: {},
        qualityDict: {},
        genderDict: [],
        positionDict: {},
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        currentList: [],
        tagType: {dqr: '', ywc: 'success', yzf: 'info', ybh: 'danger'},
        needType: {dgj: '', dyhqr: '', ywc: 'success', ycd: '', ygb: 'danger'}
      }
    },
    created() {
      this.initDict()
    },
    methods: {
      // 初始化
      init(id) {
        this.visible = true
        this.needId = this.$deepClone(id)
        this.getNeedInfo(this.needId)
      },
      initDict() {
        this.$getDictMap('NEED_STATUS,NEED_SOURCE,NEED_QUALITY', (dict) => {
          this.statusDict = dict.NEED_STATUS
          this.sourceDict = dict.NEED_SOURCE
          this.qualityDict = dict.NEED_QUALITY
        })
      },
      getNeedInfo(id) {
        this.$http({
          url: this.$http.adornUrl(this.$api.NEED.INFO + '/'+ id),
          method: 'post'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm = data.data
            if (this.dataForm.solutionList.length > 0) {
              this.totalPage = this.dataForm.solutionList.length
              this.currentList = this.dataForm.solutionList.splice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize)
            }
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      closeCallback(id) {
        this.addOrUpdateVisible = false
        if (!checkIsNull(id)) {
          this.getNeedInfo(id)
        }
      },
      // 每页数
      sizeChangeHandle(val) {
        this.pageSize = val
        this.pageIndex = 1
        this.currentList = this.dataForm.solutionList.splice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize)
      },
      // 当前页
      currentChangeHandle(val) {
        this.pageIndex = val
        this.currentList = this.dataForm.solutionList.splice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize)
      },
      closeNeed() {
        this.$prompt('关闭原因：', '关闭需求', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'textarea',
          inputPlaceholder: '请输入关闭原因',
          type: 'warning'
        }).then(({value}) => {
          this.$http({
            url: this.$http.adornUrl(this.$api.NEED.CLOSE),
            method: 'post',
            data: this.$http.adornData(this.dataForm)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功，已驳回',
                type: 'warn',
                duration: 1500,
                onClose: () => {
                  this.$emit('goBack', true)
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      detailSolutionInfo(id) {
        this.detailVisible = true
        this.$nextTick(() => {
          this.$refs.detailSolution.init(id)
        })
      }
    }
  }
</script>

<style scoped>
  .i-row {
    display: flex;
    /*height: 36px;*/
    line-height: 36px;
    color: #303133;
  }

  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }

  .i-row label {
    font-weight: bold;
    color: #1a2633;
  }

  .el-uploader {
    width: 180px;
    height: 160px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-left: 1.5rem;
  }

  .avatar-uploader .el-uploader:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 14px;
    color: #8c939d;
    width: 140px;
    height: 110px;
    line-height: 150px;
    text-align: center;
  }

  .zwsj {
    margin-left: 450px;
    width: 300px;
  }
</style>
